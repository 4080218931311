import memoize from 'lodash/memoize'
import hash from 'object-hash'
import { TimelineMarker, TimelineMarkerType } from '@/organisms/AngelPlayer'
import { Maybe, UserReaction } from '@/types/codegen-federation'

export const TIMELINE_ROLLUP_INTERVAL_MILLIS = 4000

export const reactionsToTimelineMarkers = memoize(
  (reactions: UserReaction[], markerType: TimelineMarkerType): TimelineMarker[] => {
    return reactions?.map((reaction?: UserReaction) => ({
      duration: 1,
      markerType,
      time: (reaction?.reactedAt || 0) / 1000,
    }))
  },
  (reactions) => {
    return hash(
      reactions.reduce((result, reaction) => `${result}_${reaction.id}`, ''),
      { algorithm: 'md5' },
    )
  },
)

type ClosestResult = {
  closest: UserReaction
  diff: number
}

export const closestMarker = (markers: UserReaction[], timeToCheck: number): ClosestResult => {
  const getDiff = (markerTime: Maybe<number> | undefined, time: number) => Math.abs((markerTime || 0) - time)

  let closest: UserReaction = markers[0]
  let smallestDiff = getDiff(closest?.reactedAt, timeToCheck)

  for (let i = 1; i < markers.length; i++) {
    const marker = markers[i]
    const diff = getDiff(marker?.reactedAt, timeToCheck)

    if (diff < smallestDiff) {
      smallestDiff = diff
      closest = marker
    }
  }

  return { closest, diff: smallestDiff }
}
