import React from 'react'
import { OneColorIconProps, getHexColor } from '../utils'

export const CaretLeftIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 19.5L7.5 12L15 4.5" stroke={hex} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
