import React, { ChangeEventHandler, ReactNode, TextareaHTMLAttributes, useState } from 'react'
import classNames from 'classnames'
import { When } from 'react-if'

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string
  label?: ReactNode
  onChange: ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>
  className?: string
  inputClasses?: string
  placeholder?: string
  isValid?: boolean
  required?: boolean
  usePlaceholderAsLabel?: boolean
  labelClassName?: string
  containerClassName?: string
  error?: boolean
  helperText?: string
}

const defaultInputClasses = classNames(
  'w-full',
  'p-4',
  'focus-visible:ring-transparent',
  'focus-visible:ring-0',
  'focus-visible:ring-offset-0',
  'focus-visible:ring-offset-transparent',
  'border-2',
  'rounded-lg',
  'border-gray-300',
)

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      value,
      label,
      onChange,
      onBlur,
      className,
      isValid = true,
      inputClasses = defaultInputClasses,
      usePlaceholderAsLabel = false,
      labelClassName = 'text-gray-600',
      containerClassName,
      error,
      helperText,
      ...props
    },
    ref,
  ) => {
    const [touched, setTouched] = useState(false)
    const [isActive, setActive] = useState(false)

    const isInvalid = touched && !isValid

    return (
      <label className={classNames('mt-2 block', containerClassName)}>
        <When condition={!!label}>
          <div
            className={classNames('-mb-6 pt-6 duration-300 text-sm relative', labelClassName, {
              '!mb-0 !pt-0': isActive || value || usePlaceholderAsLabel,
            })}
          >
            {label}
          </div>
        </When>
        <textarea
          ref={ref}
          data-testid={label}
          value={value}
          className={classNames(inputClasses, isInvalid ? 'border-red ' : 'border-gray-600', className)}
          onFocus={() => setActive(true)}
          onChange={onChange}
          onBlur={(e) => {
            setActive(false)
            setTouched(true)
            onBlur?.(e)
          }}
          {...props}
        />
        {helperText && (
          <span className={classNames('float-right text-sm', error ? 'text-red-500' : 'text-core-gray-400')}>
            {helperText}
          </span>
        )}
      </label>
    )
  },
)

TextArea.displayName = 'React.forwardRef(TextArea)'
