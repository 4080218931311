import React from 'react'
import classNames from 'classnames'
import { LinkButton } from '@/atoms/Button'
import { GildedBorderContainer } from '@/atoms/GildedBorderContainer'
import { HandsOutlineIcon } from '@/atoms/Icons/HandsOutlineIcon'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { LabelMD, ParagraphSM } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

const backgroundImgPaths = {
  mobile:
    'https://images.angelstudios.com/image/upload/v1728653395/angel-studios/guild/share/Property_1_background-desk-sm.png',
  tablet:
    'https://images.angelstudios.com/image/upload/v1728653394/angel-studios/guild/share/Property_1_background-desk-md.png',
  desktop:
    'https://images.angelstudios.com/image/upload/v1728653394/angel-studios/guild/share/Property_1_background-desk-lrg.png',
  wide: 'https://images.angelstudios.com/image/upload/v1728653394/angel-studios/guild/share/Property_1_background-desk-xl.png',
}

interface Props {
  className?: string
  onClick: () => void
  ctaPath?: string
}

export const GuildContentLinkingBanner: React.FC<Props> = ({ className, onClick, ctaPath }) => {
  const { t } = useTranslate('watch')

  return (
    <div className={classNames('pt-[20px] mx-0 mb-0 rounded-3xl md:w-full', className)}>
      <GildedBorderContainer>
        <div className="relative rounded-xl bg-core-gray-950">
          <ResponsiveBackgroundImage
            src={backgroundImgPaths.desktop}
            className="rounded-xl bg-cover bg-right bg-no-repeat"
            preBackgroundStyles="linear-gradient(180deg, rgba(21, 21, 21, 0.3) 60%, #151515 100%), "
            srcUrls={{
              xs: backgroundImgPaths.mobile,
              sm: backgroundImgPaths.mobile,
              md: backgroundImgPaths.tablet,
              lg: backgroundImgPaths.desktop,
              xl: backgroundImgPaths.desktop,
              '2xl': backgroundImgPaths.wide,
            }}
          />
          <div className="relative mx-auto max-w-screen-lg p-4 px-8">
            <div className="relative flex min-h-[64px] flex-col items-center justify-center gap-3 text-center lg:flex-row lg:gap-10">
              <ParagraphSM className="text-balance lg:text-center" weight="bold">
                {t('guildMemberShareFree', 'As a Guild Member, you can now share this film for free!')}
              </ParagraphSM>

              <LinkButton
                className="rounded-xl border-0 bg-white/20 px-6 py-2 backdrop-blur-xl	"
                href={`${ctaPath}?share=true`}
                onClick={onClick}
                variant="plain"
                target="_self"
                prefetch
              >
                <div className="relative flex w-full flex-row items-center justify-center gap-1.5 text-white">
                  <HandsOutlineIcon size={24} color="current" />
                  <LabelMD className="mr-1 whitespace-nowrap">{t('shareThisFilm', 'Share This Film')}</LabelMD>
                </div>
              </LinkButton>
            </div>
          </div>
        </div>
      </GildedBorderContainer>
    </div>
  )
}
