import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const DownloadIcon: React.FC<OneColorIconProps> = ({ color = 'black', className, size }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1999 21.6745C16.0222 21.6745 15.8555 21.6465 15.6999 21.5905C15.5444 21.5354 15.3999 21.4412 15.2666 21.3079L10.4666 16.5079C10.2222 16.2634 10.0999 15.9523 10.0999 15.5745C10.0999 15.1968 10.2222 14.8857 10.4666 14.6412C10.711 14.3968 11.0275 14.2688 11.4159 14.2572C11.8053 14.2465 12.1222 14.3634 12.3666 14.6079L14.8666 17.1079V7.57454C14.8666 7.19677 14.9946 6.87988 15.2506 6.62388C15.5057 6.36877 15.8222 6.24121 16.1999 6.24121C16.5777 6.24121 16.8946 6.36877 17.1506 6.62388C17.4057 6.87988 17.5333 7.19677 17.5333 7.57454V17.1079L20.0333 14.6079C20.2777 14.3634 20.5946 14.2465 20.9839 14.2572C21.3724 14.2688 21.6888 14.3968 21.9333 14.6412C22.1777 14.8857 22.2999 15.1968 22.2999 15.5745C22.2999 15.9523 22.1777 16.2634 21.9333 16.5079L17.1333 21.3079C16.9999 21.4412 16.8555 21.5354 16.6999 21.5905C16.5444 21.6465 16.3777 21.6745 16.1999 21.6745ZM8.19993 27.5745C7.4666 27.5745 6.83904 27.3137 6.31726 26.7919C5.7946 26.2692 5.53326 25.6412 5.53326 24.9079V22.2412C5.53326 21.8634 5.66082 21.5465 5.91593 21.2905C6.17193 21.0354 6.48882 20.9079 6.8666 20.9079C7.24438 20.9079 7.56126 21.0354 7.81726 21.2905C8.07237 21.5465 8.19993 21.8634 8.19993 22.2412V24.9079H24.1999V22.2412C24.1999 21.8634 24.3279 21.5465 24.5839 21.2905C24.839 21.0354 25.1555 20.9079 25.5333 20.9079C25.911 20.9079 26.2275 21.0354 26.4826 21.2905C26.7386 21.5465 26.8666 21.8634 26.8666 22.2412V24.9079C26.8666 25.6412 26.6057 26.2692 26.0839 26.7919C25.5613 27.3137 24.9333 27.5745 24.1999 27.5745H8.19993Z"
        fill={getHexColor(color)}
      />
    </svg>
  )
}
