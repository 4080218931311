import { When } from 'react-if'
import { EyebrowMD } from '@/atoms/Text'

interface ContentRatingBadgeProps {
  contentRating?: string
}

export const ContentRatingBadge: React.FC<ContentRatingBadgeProps> = ({ contentRating }) => {
  const formattedRating = contentRating?.toUpperCase().replace('_', '-')
  return (
    <When condition={formattedRating && formattedRating?.length > 0}>
      <EyebrowMD className="w-fit whitespace-nowrap rounded border px-1">{formattedRating}</EyebrowMD>
    </When>
  )
}
