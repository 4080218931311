import React, { ChangeEvent, FocusEvent, InputHTMLAttributes, useState, useCallback } from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value?: number
  ref?: React.MutableRefObject<null | HTMLInputElement>
  onValueChange: (value: string) => void
}

export const CurrencyInput: React.FC<Props> = ({ value, onValueChange, ...props }) => {
  const [heldValue, setHeldValue] = useState<string>(value?.toString() || '')

  const assignNewValue = useCallback(
    (newValue: string) => {
      const changeValue = (val: string) => {
        setHeldValue(val)
        onValueChange(val)
      }

      if (!newValue || typeof newValue !== 'string') {
        changeValue('')
        return
      }

      const [integer, decimal] = newValue.split('.')

      if (!integer) return

      if (!decimal) {
        changeValue(integer + '.00')
        return
      }
      if (decimal.length === 1) {
        changeValue(newValue + '0')
        return
      }

      changeValue(integer + '.' + decimal.slice(0, 2))
    },
    [setHeldValue, onValueChange],
  )

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '0' || event.target.value === '0.00') {
      setHeldValue('')
    }
  }

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    assignNewValue(event.target.value)
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHeldValue(event.target.value)
  }

  return (
    <div className="flex flex-row items-center space-x-1">
      <label htmlFor="price-input">$</label>
      <input
        name="price-input"
        data-testid="currency-input-active"
        role="textbox"
        {...props}
        type="number"
        step="0.01"
        value={heldValue}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </div>
  )
}
