import React from 'react'
import { When } from 'react-if'
import { Button } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { Input, TextArea } from '@/atoms/Input'
import { HeadingLG, ParagraphXL } from '@/atoms/Text'
import { Modal } from '@/molecules/Modal'
import { CatalogTitle } from '@/services/ContentCatalog'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  showModal: boolean
  handleModalClose: () => void
  createCopyLink: () => void
  displayName: string
  recipient: string
  text: string
  setRecipient: (value: string) => void
  setText: (value: string) => void
  moviePosterPath?: string | null
  catalogTitle?: CatalogTitle
  shareUrl: string
  handleLinkCopied?: () => void
  isCopied?: boolean
  handleNewShare?: () => void
  isCreateLoading?: boolean
}

export const GuildContentLinkingModal: React.FC<Props> = ({
  showModal,
  handleModalClose,
  createCopyLink,
  displayName,
  recipient,
  setRecipient,
  text,
  setText,
  moviePosterPath,
  catalogTitle,
  shareUrl,
  handleLinkCopied,
  isCopied = false,
  handleNewShare,
  isCreateLoading = false,
}) => {
  const { t } = useTranslate('watch')
  const hasShareUrl = Boolean(shareUrl && shareUrl.length > 0)

  return (
    <Modal
      isOpen={showModal}
      onClose={handleModalClose}
      panelClasses="md:min-w-[88vw] lg:min-w-min !p-4 !pb-8 !max-w-[834px]"
      className=""
    >
      <div className="flex flex-col">
        <div className="flex items-center justify-end">
          <div onClick={handleModalClose} className="cursor-pointer">
            <CloseIcon color="black" size={32} className="cursor-pointer" />
          </div>
        </div>
        <div className="px-6">
          <div className="flex flex-col gap-4">
            <HeadingLG weight="bold">{t('shareGoodnessWithFriend', 'Share this goodness with a friend!')}</HeadingLG>
            <ParagraphXL color="core-gray-700">
              {t(
                'sendALinkHelpAFriendManyLinks',
                'As a Guild member, you can send a link and help a friend or loved one enjoy this title for free. As a Guild member you can share as many links as you would like. Share now!',
              )}
            </ParagraphXL>
            <ParagraphXL weight="bold">
              {t('sharePersonalizedMessage', 'Share with a personalized message')}:
            </ParagraphXL>
            <div className="flex w-full flex-row gap-8 rounded-2xl bg-core-gray-100 px-14 py-10">
              <div>
                {moviePosterPath && (
                  <div className="block overflow-hidden rounded-lg">
                    <img
                      src={getCloudinaryImageUrl({ path: moviePosterPath, width: 150, height: 225 })}
                      className="min-h-[225px] min-w-[150px]"
                      alt={t('moviePosterAlt', '{{name}} Movie Poster', { name: catalogTitle?.title })}
                      height={225}
                      width={150}
                    />
                  </div>
                )}
              </div>
              <div className="w-full">
                <ParagraphXL weight="bold">{t('hello', 'Hello')}:</ParagraphXL>
                <Input
                  placeholder={t('enterRecipientName', 'Enter recipient name')}
                  name="recipient"
                  type="text"
                  label={undefined}
                  onChange={(e) => setRecipient(e.target.value)}
                  value={recipient}
                  containerClassName="w-full"
                  labelClassName="!hidden !h-0"
                  className="photon-caption-md w-full rounded-[10px] !border-[1.5px] !border-core-gray-300 p-4 text-core-gray-950 placeholder:text-core-gray-500"
                />
                <TextArea
                  placeholder={t('enterMessage', 'Enter message')}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  containerClassName="w-full mt-4"
                  labelClassName="!hidden !h-0"
                  className="photon-caption-md min-h-[86px] w-full rounded-[10px] !border-[1.5px] !border-core-gray-300 p-4 text-core-gray-950 outline-none placeholder:text-core-gray-500"
                />
                <div className="flex flex-row gap-1">
                  <ParagraphXL weight="bold">{t('from', 'From')}:</ParagraphXL>
                  <ParagraphXL weight="medium">{displayName}</ParagraphXL>
                </div>
                <When condition={!hasShareUrl}>
                  <Button
                    variant="core-gray-900"
                    className="mt-8 min-w-[148px] px-7 py-[17px]"
                    onClick={createCopyLink}
                  >
                    <When condition={isCreateLoading}>
                      <LoadingSpinnerIcon />
                    </When>
                    <When condition={!isCreateLoading}>{t('createCopyLink', 'Create and Copy Link')}</When>
                  </Button>
                </When>
                <When condition={hasShareUrl && !isCopied}>
                  <div className="mt-8 flex max-h-[60px] flex-row">
                    <div className="flex max-w-[320px] items-center justify-start overflow-hidden text-nowrap rounded-l-lg border-[1.5px] border-core-gray-300 bg-white px-4 text-core-gray-950">
                      {shareUrl}
                    </div>
                    <Button
                      variant="core-gray-900"
                      className="m-0 min-w-fit rounded-l-none rounded-r-lg px-3 py-[17px]"
                      onClick={handleLinkCopied}
                    >
                      <CopyIcon color="white" size={32} className="mr-1.5" />
                      {t('copyLink', 'Copy Link')}
                    </Button>
                  </div>
                </When>
                <When condition={hasShareUrl && isCopied}>
                  <Button variant="core-gray-900" className="mt-8 px-7 py-[17px]" onClick={handleNewShare}>
                    {t('shareWithAnotherFriend', 'Share With Another Friend')}
                  </Button>
                </When>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
