import React from 'react'
import classNames from 'classnames'
import { ReactFCC } from '@/types/react'

interface Props {
  className?: string
}

export const GildedBorderContainer: ReactFCC<Props> = ({ className, children }) => {
  return (
    <div className={classNames('relative z-10 p-[2px]', className)}>
      <div
        className="rounded-[calc(1rem-2px)] p-[1px]"
        style={{
          backgroundImage:
            'linear-gradient(166.78deg, #EDD7C6 1.42%, #D6A177 14.6%, #FEDEC6 39.98%, #D48D59 60.48%, #A66141 85.7%, #612B12 94.6%)',
        }}
      >
        {children}
      </div>
    </div>
  )
}
