import { EyebrowMD } from '@/atoms/Text'

interface HighDefinitionBadgeProps {
  slug: string
}

export const HighDefinitionBadge = ({ slug }: HighDefinitionBadgeProps) => {
  // there be a bug with 4k, so we've turned it off for a bit
  // const definition = slug === slugs.cabrini ? '4K' : 'HD'
  const definition = slug ? 'HD' : 'HD'
  return <EyebrowMD className="w-fit whitespace-nowrap rounded border px-1">{definition}</EyebrowMD>
}
