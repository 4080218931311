import React from 'react'
import { getHexColor, TwoColorIconProps } from '../utils'

export const EmptyCircleIcon: React.FC<TwoColorIconProps> = ({
  size = 16,
  color1 = 'gray-300',
  color2 = 'white',
  className,
}) => {
  const outlineHex = getHexColor(color1)
  const fillHex = getHexColor(color2)

  return (
    <svg
      data-testid="empty-circle-icon"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill={fillHex} />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={outlineHex} />
    </svg>
  )
}
