import React from 'react'
import classNames from 'classnames'
import { LinkButton } from '@/atoms/Button'
import { PayItForwardIcon } from '@/atoms/Icons/PayItForwardIcon'
import { paths } from '@/constants/paths'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'

interface PayItForwardButtonProps {
  projectSlug: string
  className?: string
  onClick?: () => void
  target?: '_blank'
  pifEnabled?: boolean
  utmSource?: string
}

export const PayItForwardButton: React.FC<PayItForwardButtonProps> = ({
  className,
  projectSlug,
  onClick,
  target,
  pifEnabled: pifEnabledOverride,
  utmSource,
}) => {
  const { pifEnabled } = useProjectMeta()
  const shouldRender = pifEnabledOverride ?? pifEnabled
  const utm = utmSource ? `?utm_medium=web&utm_source=${utmSource}` : ''

  return shouldRender ? (
    <LinkButton
      className={classNames('py-3 px-10 md:w-fit fill-white hover:fill-black backdrop-blur-md', className)}
      href={`${paths.payItForward.index}/${projectSlug}${utm}`}
      onClick={onClick}
      variant="white"
      target={target}
      prefetch
      outline
    >
      <div className="relative flex w-full flex-row items-center justify-center">
        <PayItForwardIcon color="current" className="fill-inherit" />
        <div className="ml-2">Pay it forward</div>
      </div>
    </LinkButton>
  ) : null
}
