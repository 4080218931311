import React from 'react'
import { OneColorIconProps, getHexColor } from '../utils'

export const FlagIcon: React.FC<OneColorIconProps> = ({ size = 16, color = 'white', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 21C5.53333 21 5.35417 20.9292 5.2125 20.7875C5.07083 20.6458 5 20.4667 5 20.25V4.75C5 4.53333 5.07083 4.35417 5.2125 4.2125C5.35417 4.07083 5.53333 4 5.75 4H12.975C13.1583 4 13.3167 4.05417 13.45 4.1625C13.5833 4.27083 13.6667 4.41667 13.7 4.6L14.05 6.15H19.25C19.4667 6.15 19.6458 6.22083 19.7875 6.3625C19.9292 6.50417 20 6.68333 20 6.9V14.65C20 14.8667 19.9292 15.0458 19.7875 15.1875C19.6458 15.3292 19.4667 15.4 19.25 15.4H14.2C14.0333 15.4 13.8792 15.35 13.7375 15.25C13.5958 15.15 13.5083 15.0083 13.475 14.825L13.125 13.275H6.5V20.25C6.5 20.4667 6.42917 20.6458 6.2875 20.7875C6.14583 20.9292 5.96667 21 5.75 21ZM14.875 13.9H18.5V7.65H12.775L12.3 5.5H6.5V11.775H14.4L14.875 13.9Z"
        fill={hex}
      />
    </svg>
  )
}
