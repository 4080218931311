import { useEffect, useState } from 'react'
import { load } from '@fingerprintjs/botd'
import { reportErrorToBugsnag } from '@/utils/bugsnag'

export const useBotDetection = () => {
  const [isBot, setIsBot] = useState<boolean>(false)
  useEffect(() => {
    const detectBot = async () => {
      try {
        const botd = await load()
        const isBot = botd.detect()
        setIsBot(isBot?.bot ?? false)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        reportErrorToBugsnag(err?.message ?? 'botd library failed for an unknown reason')
        setIsBot(true)
      }
    }

    detectBot()
  }, [])
  return {
    isBot,
  }
}
