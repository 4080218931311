import React from 'react'
import { getHexColor, IconColor } from '../utils'

interface DeprecatedIconProps {
  className?: string
  color?: IconColor
  height?: number
  width?: number
  onClick?: React.MouseEventHandler<SVGSVGElement>
}

/**
 * @deprecated Use the CaretDownIcon instead.
 */
export const ChevronDownIcon: React.FC<DeprecatedIconProps> = ({
  width = 14,
  height = 9,
  color = 'white',
  className,
}) => {
  return (
    <svg
      data-testid="chevron-down-icon"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1.5L6.77778 7.5L13 1.5" stroke={getHexColor(color)} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
