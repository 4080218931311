import { ANGEL_CONTENT_STARTS, getIntegerFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'

export const getUserViewCount = () => {
  return getIntegerFromLocalStorage(ANGEL_CONTENT_STARTS, 0)
}

export const incrementUserViewCount = () => {
  const currentViews = getUserViewCount()
  writeToLocalStorage(ANGEL_CONTENT_STARTS, currentViews + 1)
}
