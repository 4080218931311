import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const PayItForwardIcon: React.FC<OneColorIconProps> = ({ size = 20, color = 'none', className }) => {
  const c = getHexColor(color)

  return (
    <svg
      data-testid="pay-it-forward-icon"
      width={size}
      height={size}
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
      fill={c}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00034 9.49994C-0.999821 4.49994 2.00004 0.999997 5.00002 0.499944C8 -0.00011 9.00055 1.49994 10.0005 2.99994C11.0002 1.49994 12 -0.00011 15.0002 0.499944C18.0004 0.999997 21.5 5 13.5 13C13.5 13 13.2799 12.07 11 11.5C9.72133 11.1803 8.94391 10.7251 8.23714 10.3112C7.6837 9.98712 7.17357 9.68839 6.5 9.5C4.96625 9.07101 4.20037 9.19528 3.00034 9.49994ZM0 16V12.5C0.5 11.8333 2 10.5 4 10.5C5.92389 10.5 6.71415 10.9696 7.62072 11.5082C7.8922 11.6695 8.1741 11.837 8.5 12C9.49999 12.5 10.5 12.8333 11 13L11 13C12 13.5 11.5 15 10 14.5L7 13.5C6.25 13.25 6 13.5 6 13.5C5.5 14 5.75 15 7 15.5C9.5 16.5 10.5 16.5 11.5 16L17.5 13C17.5 13 18 13 18 14C18 15 17 15.5 17 15.5L12 18.5C12 18.5 10 19.5 9 19.5C8 19.5 7 19 7 19L0 16Z"
      />
    </svg>
  )
}
