import React from 'react'
import { getHexColor, TwoColorIconProps } from '../utils'

export const HeartIcon: React.FC<TwoColorIconProps> = ({
  size = 16,
  color1 = 'black',
  color2 = 'white',
  className,
}) => {
  const c1 = getHexColor(color1)
  const c2 = getHexColor(color2)

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14.238 13">
      <g id="Heart" transform="translate(0 0)">
        <g id="Path_117" data-name="Path 117" transform="matrix(1, 0, 0, 1, 0, 0)" fill={c2}>
          <path
            d="M 7.119097709655762 12.39398288726807 C 3.917902708053589 10.17946720123291 1.777990460395813 7.929454326629639 0.9218376874923706 5.874362945556641 C 0.1586177051067352 4.041702747344971 0.5676476955413818 2.803313016891479 1.045177698135376 2.087142944335938 C 1.697967767715454 1.108152985572815 2.884917736053467 0.4999929666519165 4.142837524414062 0.4999929666519165 C 5.188087940216064 0.4999929666519165 6.10777759552002 0.9194629788398743 6.732507705688477 1.681123018264771 L 7.119097709655762 2.152452945709229 L 7.505687713623047 1.681123018264771 C 8.130417823791504 0.9194629788398743 9.050107955932617 0.4999929666519165 10.09535789489746 0.4999929666519165 C 11.35340785980225 0.4999929666519165 12.54041767120361 1.108112931251526 13.19315814971924 2.087033033370972 C 13.67066764831543 2.803153038024902 14.0796480178833 4.041543006896973 13.31633758544922 5.874413013458252 C 12.46020412445068 7.929455757141113 10.32029247283936 10.17946720123291 7.119097709655762 12.39398288726807 Z"
            stroke="none"
            fill={c2}
          />
          <path
            d="M 7.119097709655762 11.78407382965088 C 10.84097671508789 9.156087875366211 12.29403781890869 7.028131484985352 12.85475730895996 5.682192802429199 C 13.39165782928467 4.393013000488281 13.36481761932373 3.245743036270142 12.7771577835083 2.364423036575317 C 12.21597766876221 1.522812962532043 11.18836784362793 0.9999929666519165 10.09535789489746 0.9999929666519165 C 9.202667236328125 0.9999929666519165 8.420268058776855 1.35450291633606 7.892287731170654 1.998212933540344 L 7.119097709655762 2.94087290763855 L 6.345907688140869 1.998212933540344 C 5.817927837371826 1.35450291633606 5.03552770614624 0.9999929666519165 4.142837524414062 0.9999929666519165 C 3.049957752227783 0.9999929666519165 2.02240777015686 1.522853016853333 1.461187720298767 2.364532947540283 C 0.8734877109527588 3.245903015136719 0.8465977311134338 4.393133163452148 1.383387684822083 5.682083129882812 C 1.944154977798462 7.02812671661377 3.397219181060791 9.156086921691895 7.119097709655762 11.78407382965088 M 7.119097709655762 13.00000286102295 C 3.291207790374756 10.40432262420654 1.28362774848938 8.042963027954102 0.4602877199649811 6.066642761230469 C -1.745382070541382 0.7703635692596436 4.554687023162842 -1.762477874755859 7.119097709655762 1.364032983779907 C 9.683507919311523 -1.762475967407227 15.9835786819458 0.770362377166748 13.777907371521 6.066642761230469 C 12.95456790924072 8.042963027954102 10.94698810577393 10.40432262420654 7.119097709655762 13.00000286102295 Z"
            stroke="none"
            fill={c1}
          />
        </g>
      </g>
    </svg>
  )
}
